/**
 * @file impressions.jsx
 * @description Impressions component.
 * @author Felix Waßmuth (Felix | D1strict)
 * @license Exclusive property of Felix Waßmuth (Felix | D1strict)
 * @version 0.1.0
 * @since 0.1.0
 * @copyright Felix Waßmuth (Felix | D1strict), 2023
 */

import React from "react";
import { Client, Storage } from "appwrite";

/**
 * @function Impressions
 * @throws {Error} If API endpoint or project ID is not set or if the API call fails.
 * @returns {JSX.Element} Impressions component.
 */

function Impressions() {
  const client = new Client();
  const storage = new Storage(client);

  client
    .setEndpoint(process.env.REACT_APP_APPWRITE_ENDPOINT) // Your API Endpoint
    .setProject(process.env.REACT_APP_APPWRITE_PROJECT);

  const images = [
    {
      fileID: "screenshot_1",
      alt: "",
      text: "",
    },
    {
      fileID: "screenshot_2",
      alt: "",
      text: "",
    },
    {
      fileID: "screenshot_3",
      alt: "",
      text: "",
    },
    {
      fileID: "screenshot_4",
      alt: "",
      text: "",
    },
    {
      fileID: "screenshot_5",
      alt: "",
      text: "",
    },
    {
      fileID: "screenshot_6",
      alt: "",
      text: "",
    },
    {
      fileID: "screenshot_7",
      alt: "",
      text: "",
    },
    {
      fileID: "screenshot_8",
      alt: "",
      text: "",
    },
    {
      fileID: "screenshot_9",
      alt: "",
      text: "",
    },
    {
      fileID: "screenshot_10",
      alt: "",
      text: "",
    },
    {
      fileID: "screenshot_11",
      alt: "",
      text: "",
    },
    {
      fileID: "screenshot_12",
      alt: "",
      text: "",
    },
    {
      fileID: "screenshot_13",
      alt: "",
      text: "",
    },
    {
      fileID: "screenshot_14",
      alt: "",
      text: "",
    },
    {
      fileID: "screenshot_15",
      alt: "",
      text: "",
    },
    {
      fileID: "screenshot_16",
      alt: "",
      text: "",
    },
  ];

  return (
    <div className="section">
      <h2 className="title is-2 has-text-centered">Impressions</h2>
      <h3 className="subtitle is-4 has-text-centered">
        A few impressions of the “Pilot”-Design.
      </h3>
      <br />
      <div className="columns is-multiline">
        {images.map((image) => (
          <div className="column is-half" key={image.fileID}>
            <div className="card">
              <div className="card-image">
                <a
                  href={
                    "https://api.felix-d1strict.de/v1/storage/buckets/63f2483140cd690be962/files/" +
                    image.fileID +
                    "/view?project=63cd3e953da71c7c7b80"
                  }
                  target="_blank"
                  rel="noreferrer"
                >
                  <figure className="image is-4by3">
                    <img
                      src={storage.getFilePreview(
                        "63f2483140cd690be962",
                        image.fileID,
                        800,
                        600,
                        "center",
                        70,
                        undefined,
                        undefined,
                        undefined,
                        undefined,
                        undefined,
                        undefined,
                        "webp",
                      )}
                      alt={image.alt ? image.alt : ""}
                      width="800"
                      height="600"
                      loading="lazy"
                    />
                  </figure>
                </a>
              </div>
              {image.text && (
                <div className="card-content">
                  <div className="content">{image.text}</div>
                </div>
              )}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default Impressions;
